<!--
 * @Author: daipeng
 * @Date: 2019-09-09 10:39:46
 * @LastEditors: VSCode
 * @LastEditTime: 2019-09-16 17:20:29
 * @Description:
 -->
<template>
    <div class="label-setting">
        <div class="label-setting-head mr10 mb10">
            <h2>漫画名称：{{comicName}}</h2><h2>漫画ID：{{comicId}}</h2>
        </div>
        <!-- search-box -->
        <el-form class="search-box" ref="searchForm" align="left" label-width="100px" :model="searchData" inline @keyup.enter.native.prevent="searchHandle">
            <el-form-item prop="show_status" label="标签状态">
                <el-select v-model="searchData.show_status" style="width:100px" placeholder="选择状态" @change="searchHandle">
                    <el-option v-for="(item, index) in statusList" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="tag_name" label="标签名">
                <el-input v-model="searchData.tag_name" placeholder="输入标签名" clearable class="search-input" @keyup.enter.native.prevent="searchHandle"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini" @click="searchHandle">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button size="mini" @click="clearSearchHandle">重置</el-button>
            </el-form-item>
        </el-form>
        <!-- search-box-end -->
        <el-form class="search-box" ref="labelData" align="left" label-width="100px" :model="searchData">
            <el-form-item label="标签设置">
                <el-button type="primary" size="mini" @click="editLabelHandler">编辑标签</el-button>
            </el-form-item>
        </el-form>
        <ul class="label-setting-wrap">
            <li class="label-setting-item" v-for="category in tagList" :key="category.id">
                <div class="label-setting-categroy" :title="category.category_name">{{category.category_name}}</div>
                <div class="label-setting-tags">
                    <el-tag
                        :class="{'label-setting-tags-item': true, disabled: !tag.show_status }"
                        v-for="tag in category.tags"
                        :key="tag.tag_id"
                        closable
                        size="medium"
                        :disable-transitions="false"
                        @close="removeHandler(category, tag)"
                    >
                        <span class="label-setting-tags-item-inner" :title="tag.tag_name">{{tag.tag_name}}</span>
                    </el-tag>
                </div>
            </li>
        </ul>
        <dialogPlus
            center
            :visible.sync="dialogData.visible"
            :title.sync="dialogData.title"
            width="90%"
            :heightFull="true"
            @open="openDialog"
            @close="closeDialog"
            :close-on-click-modal="false"
        >
            <labelAE ref="labelAE" @cancelDialog="cancelDialog" @saved="savedHandler"/>
        </dialogPlus>
    </div>
</template>

<script>
import { searchMixin, dialogMixin } from '@/mixins';
import labelAE from './components/labelAE';

export default {
    components: { labelAE },
    mixins: [searchMixin, dialogMixin],
    data() {
        return {
            statusList: [
                { name: '全部', id: -1 },
                { name: '已启用', id: 1 },
                { name: '已禁用', id: 0 }
            ],
            searchData: {
                show_status: -1,
                tag_name: ''
            },
            labelData: {},
            comicName: '',
            comicId: '',
            tagList: []
        };
    },
    created() {
        const { comicName = '', comicId = '' } = this.$route.query;
        this.comicName = comicName;
        this.comicId = comicId;
        this.getTagList();
    },
    methods: {
        getTagList() {
            const { comicId: comic_id } = this.$route.query;
            this.$api('getComictags', { comic_id, ...this.searchData }).then(res => {
                this.tagList = res.data || [];
            });
        },
        openDialog() {
            if (this.$refs.labelAE) this.$refs.labelAE.getPublictagsList();
        },
        closeDialog() {
            if (this.$refs.labelAE) this.$refs.labelAE.resetData();
        },
        // 搜索
        searchHandle() {
            this.getTagList();
        },
        // 移除操作
        removeHandler(category, tag) {
            this.$utils.confirm('', `确定移出“${tag.tag_name}”标签吗？`, `移出中...`, (action, instance, done) => {
                category.tags.splice(category.tags.indexOf(tag), 1);
                const submit = { comic_id: this.$route.query.comicId, tag_ids: [] };
                if (this.tagList.length) {
                    this.tagList.forEach(category => {
                        category.tags.forEach(tag => submit.tag_ids.push(tag.tag_id));
                    });
                }
                return this.$api('saveComictags', submit).then(res => {
                    this.$message({ message: '删除成功', type: 'success' });
                    this.searchHandle();
                });
            });
        },
        // 取消
        cancelDialog() {
            this.searchHandle();
            this.dialogCloseHandle();
        },
        // 保存成功
        savedHandler() {
            this.searchHandle();
            this.cancelDialog();
        },
        // 清除搜索
        clearSearchHandle() {
            this.resetForm('searchForm');
            this.searchHandle();
        },
        // 编辑标签
        editLabelHandler() {
            this.changeDialog(true, '漫画标签', 2, {});
        }
    }
};
</script>

<style lang="scss" scoped>
    .label-setting{
        box-sizing: border-box;
        width: 100%;
        min-height: 100%;
        padding: 15px;
        background: #ffffff;
        &-head{
            display: flex;
            flex-flow: row nowrap;
            h2{
                margin-right: 20px;
                font-size: 14px;
            }
        }
        &-wrap{
            padding: 10px 0;
            .label-setting-item{
                display: flex;
                flex-flow: row nowrap;
                width: 100%;
                .label-setting-categroy{
                    box-sizing: border-box;
                    width: 100px;
                    font-size: 14px;
                    flex-basis: 100px;
                    padding: 5px 12px 0 0;
                    line-height: 30px;
                    text-align: right;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .label-setting-tags{
                    flex: 1 0;
                    display: flex;
                    flex-flow: row wrap;
                    align-content: flex-start;
                    &-item{
                        &-inner{
                            max-width: 150px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: inline-block;
                            vertical-align: middle;
                        }
                        margin: 5px;
                        &.disabled{
                            background: #ccc;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
</style>
